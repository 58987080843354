<template>
	<common-table
			:headers="headers"
			:listData="listData"
			@changePage="changePage"
			:loading="loading"
			:btnAction="true"
			@search="search"
			@remove="remove"
			@edit="edit"
			@save="save"
			ref="ct"
			:leftConfig="leftConfig"
			:rightConfig="rightConfig"
			pageTitle="角色"
			:listDeploy="listDeploy"
			pageKey="roleid"
			:btnActionEvent="btnActionEvent"
	>
	</common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/role.js';
import menu from './menu.vue';
import operate from "./operate.vue";
import menuPermissions from "@/mixin/menu-permissions";

export default {
	mixins: [commonCurd, menuPermissions],
	data() {
		return {
			api,
			leftConfig: [
				{type: 'input', label: '输入角色名称/代码', property: 'searchkey'}
			],
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add', rightCode: '83802'}
			],
			headers: [
				{text: '角色ID', value: 'roleid'},
				{text: '角色名称', value: 'name'},
				{text: '操作', value: 'actions', width: 120}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑',
					rightCode: '83802'
				}, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除',
					rightCode: '83803'
				}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'name',
						connector: {
							props: {
								required: true,
								label: "角色名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'roleid',
						connector: {
							props: {
								required: true,
								label: "角色代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'subevents',
						connector: {
							props: {
								required: false,
								label: "通知订阅",
								multiple: true,
								arrayType: false,
								chips: true,
							}
						}
					},
					{
						cols: 6,
						subassembly: "div",
						default: ''
					},
					{
						cols: 6,
						subassembly: menu,
						property: 'mrights',
					},
					{
						cols: 6,
						subassembly: operate,
						property: 'oprights',
						connector: {
							props: {
								api: api.load
							}
						}
					}
				]
			}
		}
	},
	methods: {
		afterSave(data) {
			this.getLoginUserInfo(data.roleid);
		},
		// 获取登录用户信息
		getLoginUserInfo(id) {
			this.axios.post('/api/users/info').then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					if (res.data.roles.includes(id)) {
						this.getMenuPermissions(() => {
							// 由于更新了自己的权限，需同步左侧菜单列表
							this.$emit('refreshMenu');
						}, true);
					}
				}
			});
		}
	},
	created() {
		this.axios.post(this.select_data, {keyname: 'msgtrigger'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.listDeploy.row[2].connector.props.items = res.data[0].values
			}
		})
	}
}
</script>

<style scoped>

</style>
