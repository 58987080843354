<template>
	<div>
		<span style="font-size: 16px; font-weight: bold">菜单权限</span>
		<div
				style="height: 2px;
				background:linear-gradient(-90deg, var(--v-primary-base), var(--v-primary-lighten2));
				width: 100px;">
		</div>
    <div style="width: 200px; margin: 10px 0">
      <v-text-field
          v-model="search"
          label="搜索"
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
      ></v-text-field>
    </div>
		<v-treeview
				ref="tree"
				:items="items"
        :search="search"
        :filter="filter"
				selectable
				v-model="selection"
				expand-icon="mdi-chevron-down"
				selected-color="primary"
				@input="input"
		>
		</v-treeview>
	</div>
</template>

<script>
import json from "@/json/menu.json";

export default {
	props: {
		value: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			items: [],
			selection: [],
      search: ''
		}
	},
  computed: {
    filter () {
      return this.selection
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
	methods: {
		input() {
			this.$emit('change', this.selection);
		}
	},
	mounted() {
		this.items = JSON.parse(JSON.stringify(json));
		this.selection = this.value;
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.v-treeview-node__label {
		font-size: 15px;
		color: #666666;
	}
}
</style>
