import json from '@/json/menu.json';

export default {
	data() {
		return {
			permissionKey: 'permissionKey',
			// 权限路径列表
			permissionPaths: {},
			permissionPathsKey: 'permissionPathsKey',
			operatePermissionKey: 'operatePermissionKey'
		}
	},
	methods: {
		// 过滤出有权限的菜单
		filterMenuJson(permissions) {
			let menus = [];
			let init = JSON.parse(JSON.stringify(json));
			this.permissionPaths = {};
			init.forEach((menu) => {
				let node = this.getPermissionNode(menu, permissions);
				if (!this.globalMethods.isEmpty(node)) {
					menus.push(menu);
				}
			});
			return menus;
		},
		// 获取有权限的节点
		getPermissionNode(item, permissions) {
			let result = null;
			if (item.children.length > 0) {
				let children = [];
				item.children.forEach(son => {
					let childrenNode = this.getPermissionNode(son, permissions);
					if (!this.globalMethods.isEmpty(childrenNode)) {
						children.push(childrenNode);
					}
				});
				if (children.length > 0) {
					item.children = children;
					result = item;
				}
			} else if (permissions.includes(item.id) && !item.permissionJson) {
				result = item;
			}
			return result;
		},
		// 获取登录用户拥有的权限集合
		getMenuPermissions(callback, init) {
			// 获取登录的用户信息
			this.axios.post('/api/users/info').then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					sessionStorage.setItem(this.permissionKey, JSON.stringify(res.data.mrights));
					sessionStorage.setItem(this.operatePermissionKey, JSON.stringify(res.data.oprights));
					this.updateRouterPermission(res.data.mrights, () => {
						callback(init ? '/index' in this.permissionPaths : res.data.mrights);
					});
				}
			});
		},
		// 更新路由权限
		updateRouterPermission(permissions, callback) {
			let init = JSON.parse(JSON.stringify(json));
			this.permissionPaths = {};
			init.forEach((menu) => {
				this.getNodeRouterPermission(menu, permissions);
			});
			sessionStorage.setItem(this.permissionPathsKey, JSON.stringify(this.permissionPaths));
			if (callback) {
				callback();
			}
		},
		// 获取节点的路由权限
		getNodeRouterPermission(item, permissions) {
			if (item.children.length > 0) {
				item.children.forEach(son => {
					this.getNodeRouterPermission(son, permissions);
				});
			} else if (permissions.includes(item.id)) {
				this.permissionPaths[item.path] = item.id;
				switch (item.path) {
					case '/coumn-manage-one':
						this.permissionPaths['/coumn-manage-two'] = item.id;
						this.permissionPaths['/coumn-manage-three'] = item.id;
						break;
					case '/pc-coumn-manage-one':
						this.permissionPaths['/pc-coumn-manage-two'] = item.id;
						this.permissionPaths['/pc-coumn-manage-three'] = item.id;
						break;
					case '/apply-for-refund':
						this.permissionPaths['/history-refund'] = item.id;
						break;
					case '/synthesize-appointment':
						this.permissionPaths['/appointment-record'] = item.id;
						break;
					case '/order-record':
						this.permissionPaths['/order-record-his'] = item.id
						break
					case '/pc-basic-info/header-login':
						this.permissionPaths['/pc-basic-info/header-login'] = item.id
						this.permissionPaths['/pc-basic-info/footer-navigation'] = item.id
						this.permissionPaths['/pc-basic-info/color-config'] = item.id
						this.permissionPaths['/pc-basic-info/terms-agreement'] = item.id
						this.permissionPaths['/pc-basic-info/banner-config'] = item.id
						break;
					case '/delivery-manage':
						this.permissionPaths['/return-request'] = item.id
						break
					case '/map-manage':
						this.permissionPaths['/route-planning'] = item.id
						break
					default:
						break;
				}
			}
		},
		// 获取系统左侧的菜单列表
		getMenusJson(callback) {
			this.getMenuPermissions((menu) => {
				callback(this.filterMenuJson(menu));
			}, false);
		}
	}
}
